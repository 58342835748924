import axios from "@/plugins/axios.js"

export const actions = {
  fetchOrders({ commit, state }, force = false) {
    if(!force && state.orders.length) return
    return new Promise((resolve, reject) => {
      axios.get("/orders")
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDERS', response.data.data.orders)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchOrder({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/orders/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDER', response.data.data.order)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchDeliveries({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/order-deliveries/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDER_DELIVERIES', response.data.data.deliveries)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

export const getters = {
   
}


export const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders
  },
  SET_ORDER(state, order){
  	state.order = order
    if(state.orders.length){
      const dataIndex = state.orders.findIndex((o) => o.id == order.id);
      Object.assign(state.orders[dataIndex], order);
    }
  },
  DELETE_ORDER(state, orderId){
    const itemIndex = state.orders.findIndex((item) => item.id == orderId)
    state.orders.splice(itemIndex, 1)
  },


  /**************** THIS IS FOR DELIVERIES********************/
  SET_ORDER_DELIVERIES(state, deliveries){
    state.order.deliveries = [...deliveries]
  },
  ADD_DELIVERY(state, delivery){
    state.order.deliveries.unshift(delivery)
  },
  UPDATE_DELIVERY(state, delivery){
    const dataIndex = state.order.deliveries.findIndex((a) => a.id == delivery.id);
    Object.assign(state.order.deliveries[dataIndex], delivery);
  },
  DELETE_DELIVERY(state, deliveryId){
    const itemIndex = state.order.deliveries.findIndex((item) => item.id == deliveryId)
      state.order.deliveries.splice(itemIndex, 1)
  },
}

export const state = {
  orders: [],
  order: {},
}


export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

