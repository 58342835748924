export default{
    methods:{
        absoluteUrl(path = ""){
            return this.$store.state.domain+path
        },
        calculateDeliveryTime(time){
            if(!time){return}
            return (time > 24) ? Math.round(time/24) + ' days' : '1 day'
        },
        isImageFile(ext){
            return  ['png','jpeg','svg','jpg','webp'].includes(ext ? ext.toLowerCase() : null)
        },
        preText(content) {
            if(!content) return;
            return content.replace(/\n/g, '<br />')
        },
        scrollToView(elID){
            if(!elID){return}
            const yOffset = -200;
            const element = document.getElementById(elID);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        },
        alertSuccess(text){
            this.$store.dispatch("alertSuccess", text)
        },
        alertError(text){
            this.$store.dispatch("alertError", text)
        },
    },
}
