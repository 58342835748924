const options = {
  truncate(value, limit) {
    if(!value) return;
    if(value.length > limit){
      return value.substring(0, limit)+' ...'
    }else{
      return value
    }
  },
  truncate_start(value, limit) {
    if(!value) return;
    if(value.length > limit){
      return '...'+value.substring(value.length, value.length - limit)
    }else{
      return value
    }
  },
  capitalize (value) {
    if (!value) return value
    value = value.toString()
    let arr = value.split(" ")
    let capitalized_array = []
    arr.forEach((word) => {
      let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
      capitalized_array.push(capitalized)
    })
    return capitalized_array.join(" ");
  },
  time(value, is24HrFormat = false) {
    if(value) {
      const date = new Date(Date.parse(value));
      let hours = date.getHours();
      const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
      if(!is24HrFormat) {
        const time = hours > 12 ? 'AM' : 'PM';
        hours = hours % 12 || 12;
        return hours + ':' + min + ' ' + time
      }
      return hours + ':' + min
    }
  },
  date_time(value, index = 0) {
    var options = [
      { weekday:'long', year:'numeric', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
      { year:'2-digit', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
      { year:'numeric', month:'short', day:'2-digit' }
    ];
    var givenDate  = new Date(value);
    return new Intl.DateTimeFormat('en-US', options[index]).format(givenDate)
  },
  date(value, fullDate = false) {
    var options = {year: 'numeric', month: 'long', day: 'numeric' };
    var givenDate  = new Date(value);
    if(!value || givenDate == 'Invalid Date'){return;}
    if(!fullDate) return givenDate.toLocaleDateString("en-US", options);
    else return givenDate.toLocaleString("en-US");
  },
  month_year(value) {
    var options = {month: 'short', year: 'numeric'};
    var givenDate  = new Date(value);
    if(!value || givenDate == 'Invalid Date'){return;}
    return givenDate.toLocaleString("en-US", options);
  },
  month(val, showYear = true) {
    val = String(val)
    const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
    if(!showYear) {
      return regx.exec(val)[1];
    }else {
      return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
    }
  },
  csv(value) {
    return value.join(', ')
  },
  filter_tags(value) {
    return value.replace(/<\/?[^>]+(>|$)/g, "")
  },
  k_formatter(num) {
    return num > 999 ? (num/1000).toFixed(1) + 'k' : num
  },
  money_format(number) {
    let i = parseFloat(number); if(isNaN(i)) {return;}
    return i.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },
  date_ago(utctime) {
    let x = new Date(utctime)
    if(!utctime || x == 'Invalid Date'){return;}
    var o = {
      sec: 1000,
      min: 60 * 1000,
      hr: 60 * 1000 * 60,
      day: 24 * 60 * 1000 * 60,
      week: 7 * 24 * 60 * 1000 * 60,
      month: 30 * 24 * 60 * 1000 * 60,
      year: 365 * 24 * 60 * 1000 * 60
    };
    var ago = function(nd, s) {
      var r = Math.round,
          dir = ' ago',
          pl = function(v, n) {
            return (s === undefined) ? n + ' ' + v + (n > 1 ? 's' : '') + dir : n + v.substring(0, 1)
          },
          ts = Date.now() - new Date(nd).getTime(),
          ii;
      if( ts < 0 ){
        ts *= -1;
        dir = ' from now';
      }
      for (var i in o) {
        if (r(ts) < o[i]) return pl(ii || 'm', r(ts / (o[ii] || 1)))
        ii = i;
      }
      return pl(i, r(ts / o[i]));
    }
    return ago(x);
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$filters = options
    // app.provide('$filters', options)
  }
}