import axios from "@/plugins/axios.js"

export const actions = {
  fetchProducts({ commit, state }, force = false) {
    if(!force && state.products.length) return;
    return new Promise((resolve, reject) => {
      axios.get("/products")
        .then((response) => {
          if(response.data.success){
            commit('SET_PRODUCTS', response.data.data.products)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchProduct({ commit }, productId) {
    return new Promise((resolve, reject) => {
      axios.get(`/products/${productId}/show`)
        .then((response) => {
          if(response.data.success){
               commit('UPDATE_PRODUCT', response.data.data.product)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export const getters = {

}


export const mutations = {
  ADD_PRODUCT(state, product) {
    state.products.unshift(product)
  },
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  UPDATE_PRODUCT(state, product) {
    Object.assign(state.product, product)
    if(state.products.length){
      const dataIndex = state.products.findIndex((o) => o.id == product.id);
      Object.assign(state.products[dataIndex], product);
    }
  },
  DELETE_PRODUCT(state, productId) {
      const ItemIndex = state.products.findIndex((item) => item.id == productId)
      state.products.splice(ItemIndex, 1)
  },

  /************** FOR DESCRIPTION *****************/
  UPDATE_DESCRIPTION(state, description){
    state.product.description = description
  },

  /************** FOR PACKAGE *****************/
  UPDATE_PACKAGES(state, packages){
    state.product.product_packages = [...packages]
  },

  /************** FOR ADDONS *****************/
  ADD_ADDON(state, addon){
    state.product.addons.unshift(addon);
  },
  UPDATE_ADDON(state, addon){
    const dataIndex = state.product.addons.findIndex((f) => f.id == addon.id)
    Object.assign(state.product.addons[dataIndex], addon)
  },
  DELETE_ADDON(state, addonId){
    const ItemIndex = state.product.addons.findIndex((item) => item.id == addonId)
    state.product.addons.splice(ItemIndex, 1);
  },


  /**************** THIS IS FOR ATTRIBUTES *************************/
  ADD_ATTRIBUTE(state, attribute){
    state.product.attributes.unshift(attribute)
  },
  UPDATE_ATTRIBUTE(state, attribute){
    const dataIndex = state.product.attributes.findIndex((a) => a.id == attribute.id);
    Object.assign(state.product.attributes[dataIndex], attribute);
  },
  DELETE_ATTRIBUTE(state, attributeId){
    const itemIndex = state.product.attributes.findIndex((item) => item.id == attributeId)
      state.product.attributes.splice(itemIndex, 1)
  },
  
  /************** FOR QUESTIONS *****************/
  ADD_QUESTION(state, question){
    state.product.questions.unshift(question);
  },
  UPDATE_QUESTION(state, question){
    const dataIndex = state.product.questions.findIndex((f) => f.id == question.id)
     Object.assign(state.product.questions[dataIndex], question)
  },
  DELETE_QUESTION(state, questionId){
    const ItemIndex = state.product.questions.findIndex((item) => item.id == questionId)
    state.product.questions.splice(ItemIndex, 1);
  },

  /************** FOR REQUIREMENTS *****************/
  ADD_REQUIREMENT(state, requirement){
    state.product.requirements.unshift(requirement);
  },
  UPDATE_REQUIREMENT(state, requirement){
    const dataIndex = state.product.requirements.findIndex((f) => f.id == requirement.id)
      Object.assign(state.product.requirements[dataIndex], requirement)
  },
  DELETE_REQUIREMENT(state, requirementId){
    const ItemIndex = state.product.requirements.findIndex((item) => item.id == requirementId)
    state.product.requirements.splice(ItemIndex, 1);
  },

  /************** FOR PORTFOLIOS *****************/
  ADD_PORTFOLIO(state, portfolio){
    state.product.portfolios.unshift(portfolio);
  },
  SET_PORTFOLIOS(state, portfolios){
    state.product.portfolios = [...portfolios];
  },
  UPDATE_PORTFOLIO(state, portfolio){
    const dataIndex = state.product.portfolios.findIndex((f) => f.id == portfolio.id)
     Object.assign(state.product.portfolios[dataIndex], portfolio)
  },
  DELETE_PORTFOLIO(state, portfolioId){
    const ItemIndex = state.product.portfolios.findIndex((item) => item.id == portfolioId)
    state.product.portfolios.splice(ItemIndex, 1);
  }
}

export const state = {
  products: [],
  product:{}
}


export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

