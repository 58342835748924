import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store/store";

// axios
import axiosClient from "@/plugins/axios.js"

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import VueFeather from 'vue-feather';
import Toast from "vue-toastification";
const toastOptions = {
  // You can set your default options here
};
import Swal from 'sweetalert2/dist/sweetalert2.js'
window.Swal = Swal

import '@/assets/scss/config/app.scss';

// Filters
import filters from '@/filters/filters'

// Mixins
import mixins from './mixins/mixins'
const app = createApp(App)
          .mixin(mixins)
          .use(store)
          .use(router)
          .use(BootstrapVue3)
          .use(Toast, toastOptions)
          .component(VueFeather.type, VueFeather)
          .use(vClickOutside)
          .use(filters);
app.config.globalProperties.$http = axiosClient;
app.mount('#app')



    