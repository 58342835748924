// import useJwt from '@/auth/jwt/useJwt'

import env from '../env.js'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem(env.localUserKey) ? true : false
}

export const getUserData = () => {
    const getLocalUser = localStorage.getItem(env.localUserKey);
    if(!getLocalUser){ return null}
    const storedData =  JSON.parse(unescape(atob(getLocalUser)))
    return typeof storedData === 'object' && storedData !== null
         ? storedData.user
         : null

}

