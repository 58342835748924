import env from '../env.js'
import countries from './db/countries.js'
import { getUserData } from '@/auth/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
        shortDesc: "Top-rated branding agency."
    },
    windowWidth: null,
    countries: countries,
    coupons : [],
}

export default state
