import { createWebHistory, createRouter } from "vue-router";
import { isUserLoggedIn } from '@/auth/utils'
import routes from './routes'
import env from '../env.js'

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
});

router.afterEach((to, from) => {
  // Remove initial loading
  // const appLoading = document.getElementById('appplicationBusyPage')
  //   if (appLoading) {
  //       appLoading.style.display = "none";
  //   }
  document.title = to.meta.title+ ' | '+ env.appName
})

router.beforeEach((to, from, next) => {
    const isLoggedIn = isUserLoggedIn()
    if(to.meta.authRequired) {
      if (!isLoggedIn) return next({path: '/login', query:{ token: to.query.token, intended: to.path }});
    }
    if(to.meta.guestRequired) {
      if (isLoggedIn) return next({path: '/' });
    }
    return next();
});
export default router


