
import axios from "@/plugins/axios.js"

const actions = {
  createUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.post("/users/create", user)
      .then((response) => {
        commit('ADD_USER', response.data.data.user)
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchUsers({ commit, state }) {
    if(state.users.length) return
    return new Promise((resolve, reject) => {
      axios.get("/users")
        .then((response) => {
          if(response.data.success){
            commit('SET_USERS', response.data.data.users)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchRoles({ commit, state }) {
    if(state.roles.length) return
    return new Promise((resolve, reject) => {
      axios.get("/roles")
        .then((response) => {
          if(response.data.success){
            commit('SET_ROLES', response.data.data.roles)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/${userId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_USER', response.data.data.user)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const mutations = {
    ADD_USER(state, user) {
      state.users.push(user)
    },
    SET_USERS(state, users) {
      state.users = [...users]
    },
    SET_ROLES(state, roles) {
      state.roles = roles
    },
    SET_USER(state, user) {
      state.user = {...user}
      if(state.users.length){
          const dataIndex = state.users.findIndex((u) => u.id == user.id);
          Object.assign(state.users[dataIndex], user);
      }
    },
    DELETE_USER(state, userId) {
        state.user = {}
        const userIndex = state.users.findIndex((u) => u.id == userId)
        state.users.splice(userIndex, 1)
    },
}

  
const getters = {
   
}

const state = {
    users: [],
    roles: [],
    user: {},
}

export default {
    isRegistered: true,
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
}
  